import React from 'react'

const Cards = () => {
  return (
    <div className='max-w-[1640px] mz-auto p-4 py-12 grid md:grid-cols-3 gap-6'>
        {/** Card */}
        <div className='rounded-xl relative'>
            {/** Overlay  */}
            <div className='absolute w-full h-full bg-black/50 rounded-xl text-white'>
                <p className='font-bold text-2xl px-2 pt-4'>Brunch Menu</p>
                <p className='px-2'>Coming Soon!!</p>
                <button className='border-white bg-white text-black mx-2 absolute bottom-4'>Order Now</button>
            </div>
        <img className='max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl'
        src='https://images.pexels.com/photos/376464/pexels-photo-376464.jpeg?auto=compress&cs=tinysrgb&w=800' alt='/'/>
        </div>
           {/** Card */}
           <div className='rounded-xl relative'>
            {/** Overlay  */}
            <div className='absolute w-full h-full bg-black/50 rounded-xl text-white'>
                <p className='font-bold text-2xl px-2 pt-4'>New On The Block</p>
                <p className='px-2'>Added Daily</p>
                <button className='border-white bg-white text-black mx-2 absolute bottom-4'>Order Now</button>
            </div>
        <img className='max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl'
        src='https://images.pexels.com/photos/1448721/pexels-photo-1448721.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=236.25&fit=crop&h=382.5' alt='/'/>
        </div>
           {/** Card */}
           <div className='rounded-xl relative'>
            {/** Overlay  */}
            <div className='absolute w-full h-full bg-black/50 rounded-xl text-white'>
                <p className='font-bold text-2xl px-2 pt-4'>Sweet Tooth? Got you Covered!</p>
                <p className='px-2'>Tasty Treats</p>
                <button className='border-white bg-white text-black mx-2 absolute bottom-4'>Order Now</button>
            </div>
        <img className='max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl'
        src='https://images.pexels.com/photos/47062/bananas-dessert-ice-cream-fruit-47062.jpeg?auto=compress&cs=tinysrgb&w=800' alt='/'/>
        </div>

    </div>
  )
}

export default Cards

import React from 'react'

const Hero = () => {
  return (
    <div className='max-w-[1640px] mx-auto p-4'>
        <div className='max-h-[500px] relative'>
            {/**Overlay  */}
            <div className='absolute w-full text-gray-200 max-h-[500] bg-black/40 flex flex-col justify-center'>
                <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>Food <span className='text-green-600'>Delivery</span></h1>
                <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'> <span className='text-green-600'>Like</span> No Other!</h1>
            </div>
            <img className='w-full max-h-[500px] object-cover' src=
'https://images.pexels.com/photos/2983101/pexels-photo-2983101.jpeg?auto=compress&cs=tinysrgb&w=800' alt='/' />
        </div> 
    </div>
  )
}

export default Hero



